<template>
  <div>
    <b-row>
      <b-col
        sm="12"
        md="8"
        lg="8"
      >
        <b-card no-body>
          <div class="pt-2 pl-3">
            <b-form-group>
              <b-form-radio-group
                v-model="currentStatus"
                :options="statusOptions"
                switches
              />
            </b-form-group>
          </div>
        </b-card>
      </b-col>
      <b-col
        sm="12"
        md="4"
        lg="4"
      >
        <div class="card">
          <b-input-group
            class="input-group-merge p-1 mt-0"
            size="sm"
          >
            <b-form-input
              v-model="currentTicket"
              size="sm"
              placeholder="Entrer le ticket pour rechercher"
            />
            <b-input-group-append is-text>
              <span
                class="cursor"
                @click="getTransactionById"
              >
                <feather-icon icon="SearchIcon" />
              </span>
            </b-input-group-append>
            <b-input-group-append is-text>
              <span
                class="cursor"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </span>
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <div
      v-if="loadSearch"
      class="text-center"
    >
      <b-spinner variant="primary" />
      <div class="d-flex justify-content-center">
        <p>Recherche en cours</p>
      </div>
    </div>
    <div
      v-if="loaded"
      class="text-center"
    >
      <b-spinner variant="primary" />
    </div>
    <div v-if="!loaded && items.length > 0">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="mb-2"
      >
        <b-card
          :class="{'d-flex flex-row':true}"
          no-body
        >
          <div class="d-flex flex-grow-1 min-width-zero">
            <div class="card-body p-1 d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
              <p class="mb-0 font-weight-bold text-primary w-15 w-sm-100">
                {{ item.ticket }}
              </p>
              <p class="mb-0 text-small w-15 w-sm-100">
                <b-icon
                  v-if="item.sentCurrency.ismobile"
                  class="text-danger"
                  icon="phone"
                  font-scale="1"
                />
                {{ item.senderAddress !== null ? truncate(item.senderAddress) : 'Portefeuille' }}
              </p>
              <p class="mb-0 text-small w-20 w-sm-100">
                <b-icon
                  class="text-danger"
                  icon="arrow-up"
                  font-scale="1"
                />
                {{ item.sentCurrency.sigle + ' ( '+ item.realSentValue.toFixed(2) + ' )' }}
              </p>
              <p class="mb-0 text-small w-15 w-sm-100">
                <b-icon
                  class="text-success"
                  icon="arrow-down"
                  font-scale="1"
                />
                {{ item.receivedCurrency.sigle }} ( {{ realReceivedValue(item) }} )
              </p>
              <!-- <p class="mb-0 text-small w-15 w-sm-100">
                  {{ item.email }}
                </p> -->
              <p
                v-b-tooltip.hover
                class="mb-0 text-small w-10 w-sm-100"
                :title="item.receiverAddress"
              >
                <b-icon
                  v-if="item.receivedCurrency.ismobile"
                  class="text-success"
                  icon="phone"
                  font-scale="1"
                />
                <b-icon
                  v-else-if="item.receivedCurrency.dev_code === 'VISA'"
                  class="text-success"
                  icon="credit-card"
                  font-scale="1"
                />
                <b-icon
                  v-else
                  class="text-success"
                  icon="arrow-down"
                  font-scale="1"
                />
                {{ truncate(item.receiverAddress) }}
              </p>
              <p class="mb-0 text-small w-10 w-sm-100">
                {{ item.created_at | moment("DD/MM/YY HH:mm") }}
              </p>
              <p
                v-if="searchTransaction"
                class="mb-0 text-small w-10 w-sm-100"
              >
                <b-badge
                  variant="primary"
                  pill
                >
                  {{ getStateName(searchTransaction.state) }}
                </b-badge>
              </p>
            </div>
            <div class="custom-control custom-checkbox pl-1 align-self-center pt-1 pr-1">
              <div class="mb-1 text-small w-5 w-sm-100">
                <b-dropdown
                  variant="link"
                  dropright
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <span class="p-1">
                      <b-icon
                        class="text-primary"
                        icon="three-dots-vertical"
                        font-scale="1.1"
                      />
                    </span>
                  </template>
                  <b-dropdown-item @click="openModal(item)">
                    <span class="ml-1">Détails</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div v-if="!loaded && !loadSearch && items.length === 0">
      <b-card
        :class="{'d-flex flex-row':true}"
        no-body
      >
        <div class="pl-2 d-flex flex-grow-1 min-width-zero">
          <div class="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
            <div class="text-center">
              <p class="mb-0 font-weight-bold text-primary w-100 w-sm-100">
                <span v-if="currentStatus === 'CREATED'">Aucune transaction initiée..</span>
                <span v-else-if="currentStatus === 'PENDING'">Aucune transaction en approbation.</span>
                <span v-else-if="currentStatus === 'WAITING'">Aucune transaction en traitement.</span>
                <span v-else-if="currentStatus === 'VALID'">Aucune transaction validée.</span>
                <span v-else-if="currentStatus === 'FAILED'">Aucune transaction echouée.</span>
                <span v-else>Aucune transaction reliée a ce ticket.</span>
              </p>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div
      v-if="!loaded && items.length > 0"
      class="mt-2 mb-2"
    >
      <div class="float-left">
        <b-form-select
          v-model="perPage"
          :options="pageOptions"
        />
      </div>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
        align="right"
      />
    </div>

    <b-modal
      v-if="showDetailsModal"
      v-model="showDetailsModal"
      :no-close-on-backdrop="checkState"
      :hide-header-close="checkState"
      :no-close-on-esc="checkState"
      centered
      hide-footer
      title="Détails"
    >
      <b-row>
        <b-col sm="12">
          <b-row>
            <b-col sm="12">
              <p class="text-primary h5">
                TICKET :  {{ currentTransaction.ticket }}
              </p>
              <b-row>
                <b-col sm="12">
                  <b-table-simple
                    caption-top
                    responsive
                    hover
                  >
                    <caption class="font-weight-bold text-danger">
                      Envoyé</caption>
                    <b-tr class="w-100">
                      <b-td>
                        <span class="font-weight-bold">
                          Je donne
                        </span>
                      </b-td>
                      <b-td>
                        <!-- <b-badge
                          variant="primary"
                          pill
                        > -->
                        <img
                          :src="currentTransaction.sentCurrency.icones"
                          class=""
                          width="20"
                          height="20"
                        >
                        {{ currentTransaction.sentCurrency.sigle }}
                        <!-- </b-badge> -->
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="font-weight-bold">
                        Montant à echanger
                      </b-td>
                      <b-td>
                        <b-badge
                          variant="warning"
                          pill
                        >
                          {{ currentTransaction.realSentValue.toFixed(2) }}
                        </b-badge>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.coinSentReceptionAddress">
                      <b-td class="font-weight-bold">
                        Adresse
                      </b-td>
                      <b-td
                        id="btc-address"
                        v-b-tooltip.hover.top="'Cliquer pour copier!'"
                        @click="onAddressCopy(currentTransaction.coinSentReceptionAddress)"
                      >
                        {{ truncate(currentTransaction.coinSentReceptionAddress) }}
                      </b-td>
                    </b-tr>
                    <!-- <b-tr v-if="currentTransaction.coinSentValue">
                      <b-td class="font-weight-bold">
                        Valeur actuelle en BTC
                      </b-td>
                      <b-td>
                        {{ currentTransaction.coinSentValue }}
                      </b-td>
                    </b-tr> -->
                    <b-tr v-if="currentTransaction.sentCurrency.ismobile">
                      <b-td class="font-weight-bold">
                        Adresse d'envoi
                      </b-td>
                      <b-td>
                        {{ currentTransaction.senderAddress }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.sentCurrency.is_cryto">
                      <b-td class="font-weight-bold">
                        Message de validation
                      </b-td>
                      <b-td>
                        {{ currentTransaction.receivedMemo }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                      <b-td class="font-weight-bold">
                        Expéditeur
                      </b-td>
                      <b-td>
                        {{ currentTransaction.senderAddressName }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                      <b-td class="font-weight-bold">
                        Adresse de paiment
                      </b-td>
                      <b-td>
                        {{ currentTransaction.sentRecipientAddress }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                      <b-td class="font-weight-bold">
                        Capture d'écran
                      </b-td>
                      <b-td>
                        <a
                          :href="currentTransaction.proof[0].name"
                          target="_blank"
                          rel="noopener noreferrer"
                        >Voir</a>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="(currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL') && currentStatus === 'FAILED'">
                      <b-td class="font-weight-bold text-danger">
                        Motif d'échec
                      </b-td>
                      <b-td>
                        {{ currentTransaction.sentMemo }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.sentCurrency.ismobile && currentTransaction.sentCurrency.is_auto && currentTransaction.sentState === 'PENDING' && (currentTransaction.state === 'WAITING' || currentTransaction.state === 'PENDING')">
                      <b-td>
                        Vérifier
                      </b-td>
                      <b-td>
                        <b-button
                          variant="outline-primary"
                          class="w-100"
                          @click="getCurrentTicket(currentTransaction.id)"
                        >
                          Check
                        </b-button>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
                <b-col cols="12">
                  <div
                    v-if="checkState"
                    class="text-center mt-2 mb-1"
                  >
                    <div>
                      <b-spinner variant="primary" />
                      <p>Vérification en cours ...</p>
                    </div>
                  </div>
                </b-col>
                <b-col sm="12">
                  <b-table-simple
                    caption-top
                    responsive
                    striped
                  >
                    <caption class="font-weight-bold text-primary">
                      A recevoir</caption>
                    <b-tr class="w-100">
                      <b-td class="font-weight-bold">
                        Je recois
                      </b-td>
                      <b-td>
                        <!-- <b-badge
                          variant="primary"
                          pill
                        > -->
                        <img
                          :src="currentTransaction.receivedCurrency.icones"
                          class=""
                          width="20"
                          height="20"
                        >
                        {{ currentTransaction.receivedCurrency.sigle }}
                        <!-- </b-badge> -->
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="font-weight-bold">
                        Montant à recevoir
                      </b-td>
                      <b-td>
                        <b-badge
                          variant="warning"
                          pill
                        >
                          {{ realReceivedValue(currentTransaction) }}
                        </b-badge>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.receivedDevCode !== 'VISA'">
                      <b-td class="font-weight-bold">
                        Adresse de réception
                      </b-td>
                      <b-td
                        v-b-tooltip.hover
                        :title="currentTransaction.receiverAddress"
                      >
                        {{ truncate(currentTransaction.receiverAddress) }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.receivedDevCode === 'WAVE_CI' || currentTransaction.receivedDevCode === 'WAVE_SENEGAL' || currentTransaction.receivedDevCode === 'VISA'">
                      <b-td class="font-weight-bold">
                        Destinataire
                      </b-td>
                      <b-td>
                        {{ currentTransaction.receiverAddressName }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.receivedDevCode === 'VISA'">
                      <b-td class="font-weight-bold">
                        ID Carte
                      </b-td>
                      <b-td>
                        {{ currentTransaction.visaCardId }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.receivedDevCode === 'VISA'">
                      <b-td class="font-weight-bold">
                        N° Carte
                      </b-td>
                      <b-td>
                        {{ currentTransaction.visaLastDigit }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="(!currentTransaction.receivedCurrency.is_auto || (currentTransaction.receivedCurrency.is_crypto && !currentTransaction.receivedCurrency.sale_auto)) && currentStatus === 'SUCCESSFUL'">
                      <b-td class="font-weight-bold text-primary">
                        Message de validation
                      </b-td>
                      <b-td>
                        {{ currentTransaction.receivedMemo }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="currentTransaction.sentState === 'SUCCESSFUL' && currentTransaction.receivedState === 'FAILED' && currentTransaction.receivedCurrency.ismobile && currentTransaction.receivedCurrency.is_auto && currentStatus === 'WAITING'">
                      <b-td class="font-weight-bold text-danger">
                        Paiment non effectué
                      </b-td>
                      <b-td>
                        <b-badge
                          variant="danger"
                          pill
                        >
                          {{ currentTransaction.receivedState }}
                        </b-badge>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                  <div v-if="currentTransaction.sentState === 'SUCCESSFUL' && currentTransaction.receivedState === 'FAILED' && currentTransaction.receivedCurrency.ismobile && currentTransaction.receivedCurrency.is_auto && currentStatus === 'WAITING'">
                    Veuillez nous contacter via le support pour régulariser le paiment en nous fournissant le <span class="text-primary">ticket</span> de la transaction.
                  </div>
                </b-col>
              </b-row>
              <div
                v-if="loadConfirm"
                class="text-center"
              >
                <b-spinner variant="primary" />
              </div>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
      <hr>
      <div v-if="currentTransaction.sentCurrency.is_crypto && currentTransaction.sentCurrency.provider === 'COINPAYMENT'">
        <b-row>
          <b-col>
            <a
              :href="currentTransaction.crypto_status_url"
              target="_blank"
              class="btn btn-outline-primary w-100"
            >Voir la transaction</a>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      items: [],
      currentTicket: null,
      statusOptions: [
        { value: 'CREATED', text: 'CREEE' },
        { value: 'PENDING', text: 'EN APPROBATION' },
        { value: 'WAITING', text: 'EN TRAITEMENT' },
        { value: 'FAILED', text: 'ECHEC' },
        { value: 'SUCCESSFUL', text: 'VALIDE' },
      ],
      searchTransaction: null,
      loadSearch: false,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      checkState: null,
      perPage: 5,
      loaded: false,
      loadConfirm: false,
      totalItems: 0,
      currentStatus: this.$route.query.state ? this.$route.query.state : 'PENDING',
      currentTransaction: null,
      showDetailsModal: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllTransactions()
      },
    },
    currentPage(val) {
      if (val) {
        this.getAllTransactions()
      }
    },
    currentStatus(val) {
      if (val) {
        this.currentPage = 1
        this.currentTicket = null
        this.searchTransaction = null
        const obj = { ...this.$route.query, state: val || this.$route.path }
        this.$router.replace({ path: '/admin-history', query: obj })
        this.getAllTransactions()
      }
    },
    perPage(val) {
      if (val) {
        this.getAllTransactions()
      }
    },
  },
  mounted() {
    this.getAllTransactions()
  },
  methods: {
    truncate(item) {
      const length = 10
      return item.length <= length ? item : `${item.substring(0, length)}...`
    },
    onAddressCopy(item) {
      const container = document.getElementById('btc-address')
      this.$copyText(item, container).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copie',
            icon: 'CheckSquareIcon',
            text: `${item}`,
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copie',
            icon: 'XCircleIcon',
            text: 'Copie échouée.',
            variant: 'danger',
          },
        })
      })
    },
    realReceivedValue(item) {
      if (item.receivedCurrency.dev_code === 'PAYEER_EURO' || item.receivedCurrency.dev_code === 'PAYEER_USD') {
        return (item.receivedValue - (item.receivedValue * item.receivedCurrency.partner_fees_in)).toFixed(2)
      }
      return item.receivedValue.toFixed(2)
    },
    async getCurrentTicket(item) {
      this.checkState = true
      await this
        .$http
        .get(`/check-merchant-payment-status-in-touch/${item}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.checkState = false
          this.currentStatus = res.data.data.state
          if (res.data.data.sentState === 'SUCCESSFUL') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Vérification de la transaction',
                text: 'Paiment mobile reçu avec succès.',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else if (res.data.data.sentState === 'PENDING') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Vérification de la transaction',
                text: 'Paiment mobile en attente réception / confirmation.',
                icon: 'CheckCircleIcon',
                variant: 'warning',
              },
            })
          } else if (res.data.data.sentState === 'FAILED') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Vérification de la transaction',
                text: 'Transaction supendue.',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
          this.closeModal()
          this.getAllTransactions()
        })
        .catch(() => {
          this.checkState = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Vérification de la transaction',
              text: 'Une erreur est survenue, veuillez réessayer',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    clearSearch() {
      this.currentTicket = null
      if (this.currentStatus) return
      this.currentStatus = 'WAITING'
    },
    async getTransactionById() {
      this.loadSearch = true
      this.currentStatus = null
      this.items = []
      this.searchTransaction = await this.$http
        .get(`/find-by-ticket/${this.currentTicket}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.loadSearch = false
          this.items.push(res.data)
          return res.data
        })
        .catch(() => {
          this.loadSearch = false
          return null
        })
    },
    getStateName(item) {
      let name = null
      switch (item) {
        case 'SUCCESSFUL':
          name = 'VALIDE'
          break
        case 'FAILED':
          name = 'ECHEC'
          break
        case 'WAITING':
          name = 'EN TRAITEMENT'
          break
        case 'PENDING':
          name = 'EN APPROBATION'
          break
        case 'CREATED':
          name = 'INITALISER'
          break
        case 'INITIATED':
          name = 'ECHEC'
          break
        case 'MANUAL_WAITING':
          name = 'TRAITEMENT MANUEL'
          break
        case 'NOTFOUND':
          name = 'NON TROUVE'
          break
        default:
          break
      }
      return name
    },
    async confirmPayment() {
      this.loadConfirm = true
      this.$http
        .get(`/show-transaction-crypto/${this.currentTransaction.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.loadConfirm = false
          this.closeModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('confirmpayment.title'),
              text: this.$t('confirmpayment.success'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            window.location = res.request.responseURL
          }, 2000)
        })
        .catch(() => {
          this.loadConfirm = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('confirmpayment.title'),
              text: this.$t('confirmpayment.error'),
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openModal(item) {
      this.currentTransaction = item
      this.showDetailsModal = true
    },
    closeModal() {
      this.currentTransaction = null
      this.showDetailsModal = false
    },
    async getAllTransactions() {
      this.loaded = true
      this.items = []
      const status = this.$route.query.state ? this.$route.query.state : this.currentStatus
      this.items = await
      this.$http
        .get(`/find-all-by-owner-and-state/${this.$store.getters.currentUser.id}/${status}/${this.perPage}?page=${this.currentPage}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          return data.data
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>

<style lang="scss">
.cursor {
    cursor: pointer;
  }
</style>
